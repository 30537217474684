<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">新闻快讯模板管理</h2>
        <div class="RightDiv">
          <div class="firDiv">
            <el-button type="primary" @click="addTemplate">新增模板</el-button>
          </div>
        </div>
        <el-table :data="tableData" border center>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="name" label="模板名称" align="center">
          </el-table-column>
          <el-table-column prop="number" label="新闻快讯数量" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template style="display: flex" slot-scope="scope">
              <el-button size="mini" type="primary" @click="assPer(scope.row)"><i>详情</i></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-main>
    </el-container>

    <!--新增模板弹窗-->
    <el-dialog :title="templatePopupTitle" :visible.sync="AddTemplatePopup" width="430px" top="1vh" :before-close="AddTemplatePopupColse">
      <el-form :model="AddTemplateModel" ref="AddTemplateRef" :rules="AddTemplateRules" label-width="130px">
        <el-form-item label="模板名称：" prop="name">
          <el-input v-model="AddTemplateModel.name" placeholder="请填写模板名称"></el-input>
        </el-form-item>
        <el-form-item label="标题：" prop="title">
          <el-input v-model="AddTemplateModel.title" placeholder="请填写标题"></el-input>
        </el-form-item>
        <el-form-item label="称谓：" prop="appellation">
          <el-input v-model="AddTemplateModel.appellation" placeholder="请填写称谓"></el-input>
        </el-form-item>
        <el-form-item label="正文：" prop="body">
          <el-input v-model="AddTemplateModel.body" placeholder="请填写正文"></el-input>
        </el-form-item>
        <el-form-item label="落款：" prop="inscribed">
          <el-input v-model="AddTemplateModel.inscribed" placeholder="请填写落款"></el-input>
        </el-form-item>
      </el-form>
      <el-form-item class="depar-dia btnCen">
        <el-button v-if="AddTemplateClick" type="primary" :loading="AddTemplateClicking" @click="AddTemplateParmen">{{btnSubText}}</el-button>
        <el-button v-else type="info" :loading="AddTemplateClicking"></el-button>
      </el-form-item>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    return {
      btnSubText:'提交',
      templatePopupTitle:'新增新闻快讯模板',
      AddTemplateModel:{
        name:'',
        title:'',
        appellation:'',
        body:'',
        inscribed:''
      },
      AddTemplateRules:{
        name: [
          { required: true, message: "请填写模板名称", trigger: "blur" },
        ],
        title: [
          { required: true, message: "请填写标题", trigger: "blur" },
        ],
        appellation: [
          { required: true, message: "请填写称谓", trigger: "blur" },
        ],
        body: [
          { required: true, message: "请填写正文", trigger: "blur" },
        ],
        inscribed: [
          { required: true, message: "请填写落款", trigger: "blur" },
        ]
      },
      AddTemplatePopup:false,
      AddTemplateClick:true,
      AddTemplateClicking:false,
      taskName:"",
      pertableData: [],
      multipleSelection: [],
      persondialogVisible:false,
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      pertotal:1,
      pageperNum: 1,
      pageperSize: 10,
      // 表格数据
      tableData: [],
      // 学组用户点击修改的id
      userId: "",
      studyGroupId:'',
      studyStatus:'',
      assdepartmentId:'',
      taskItem:"",
      taskId:""
    };
  },
  created() {
    this.newsTemplateList();
  },
  methods: {
    AddTemplateParmen(){

    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.newsTemplateList();
    },
    AddTemplatePopupColse() {
      this.addName = "新增新闻快讯模板";
      this.btnText = "添加";
      this.AddTemplatePopup = false;
      this.$refs.AddTemplateRef.resetFields();
      this.AddTemplateModel = {
        name:'',
        title:'',
        appellation:'',
        body:'',
        inscribed:''
      };
    },
    addTemplate(){
      this.AddTemplatePopup = true;
    },
    remainderA(time){
      if(!time){
        return time;
      }
      if(time.indexOf('-')!==-1){
        return '<div style="color: white;\n' +
            '    border: #ed4014;\n' +
            '    background-color: #ed4014;\n' +
            '    font-weight: 600;\n' +
            '    align-content: center;\n' +
            '    border-radius:25px;">'+time+'</div>';
      }
      if(time.indexOf('3天')!==-1 || time.indexOf('2天')!==-1 || time.indexOf('1天')!==-1 || time.indexOf('天') ===-1){
        return '<div style="color: white;\n' +
            '    border: #fc984b;\n' +
            '    background-color: #fc984b;\n' +
            '    font-weight: 600;\n' +
            '    align-content: center;\n' +
            '    border-radius:25px;">'+time+'</div>';
      }

      return '<div style="color: white;\n' +
          '    border: #0bc037;\n' +
          '    background-color: #0bc037;\n' +
          '    font-weight: 600;\n' +
          '    align-content: center;\n' +
          '    border-radius:25px;">'+time+'</div>';
    },
    taskChange(val){
      this.pageNum = 1;
      // console.log(val)
      this.taskItem=val
      this.newsTemplateList();
    },
    persondialogVisibleClose() {
      this.persondialogVisible=false
      this.studyGroupId=''
      this.taskId=''
      this.studyStatus=''
      this.pageperNum=1
      this.multipleSelection=[]
      this.pertableData=[]
      this.$refs.multipleTable.clearSelection()
      this.newsTemplateList()
      this.assdepartmentId=''
    },
    async newsTemplateList() {
      let data = _qs.stringify({
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
        conferenceId:window.sessionStorage.getItem('conferenceId')//会场id
      });
      let { data: res } = await this.$axios.newsTemplateList(data);
      console.log(res)
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.el-pagination{
  text-align: center;
}
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}

.btnDiv{
  text-align: center;
  margin-top: 20px;
  button {
    width: 150px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}
.depart-addnum {
  width: 100%;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
.el-main {
  .table-title {
    display: flex;
    margin-bottom: 10px;
    .index-add {
      margin-left: 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.text-ellipsis{
  width:380px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .el-textarea__inner {
  width: 220px !important;
}

/deep/ .el-input__inner {
  width: 220px !important;
}

.searSty{
  display: flex;
  margin-bottom: 20px;
  float: right;
  div{
    margin-left: 5px;
  }
}

.RightDiv{
  float: right;
  margin-bottom: 20px;
  display: flex;
  .firDiv{
    margin-left: 10px;
  }
}
</style>
